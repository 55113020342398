@import "./assets/scss/normalize.css";
@import "./assets/scss/layout";
@import "./assets/scss/fonts";
@import url("https://use.typekit.net/gia4vas.css");


// @font-face {
//     font-family: 'mr-eaves-book';
//     src: url('./assets/fonts/mr-eaves-book.woff2') format('woff2'),
//         url('./assets/fonts/mr-eaves-book.woff2') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'mr-eaves-black';
//     src: url('./assets/fonts/mr-eaves-black.woff2') format('woff2'),
//         url('./assets/fonts/mr-eaves-black.woff2') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// $font-family-book: 'mr-eaves-book';
// $font-family-black: 'mr-eaves-black';


$zero: 0px;
$presetBorder: 1px;
$presetMargin: 10px;
$presetPadding: 10px;
$heroMargin: 30px;

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    //   background: #f1f1f1;
    opacity: 0%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(3 105 161); 
    border-radius:5px;
  }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    opacity: 0.75;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

body {
    @include margin($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
    @include padding($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
}

// h1 {
//     color: #222222;
//     font-family: $font-family-black;
//     font-weight: bolder;
//     font-style: normal;
//     font-size: 30px;
//     margin: 18px 24px;
// }

// h2 {
//     color: #333333;
//     font-family: $font-family-black;
//     font-weight: 900;
//     font-style: normal;
//     font-size: 24px;
//     margin: 15px 24px;
// }

// h3 {
//     color: #0464b3;
//     font-family: $font-family-black;
//     font-weight: 900;
//     font-style: normal;
//     font-size: 20px;
//     margin: 12px 24px;
// }

// p {
//     color: #333333;
//     font-family: $font-family-black;
//     font-weight: 600;
//     font-style: normal;
//     font-size: 16px;
//     margin: 12px 24px;
// }
// .menu-wrapper p{color:#f2f2f2;}
// p>span {
//     color: #898989;
// }

#viewer {
    width: 100vw;
    height: 50vh;
}

.logo {
    width: 150px;
    height: 200px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("./assets/images/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}
.frame {
    @include divDimensions (100%, 100vh, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: 600px); //@include backgroundColor($primary, $opacity: 0.5);
    @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    @include position($position: fixed);
    z-index: 1;
    overflow-x: hidden;
    box-sizing: border-box;
    @include border($border-radius: null, $border-style: null, $border-width: 0px, $border-color: null, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
}

// .menu-wrapper {
//     @include divDimensions (100%, auto, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null); //@include backgroundColor($primary, $opacity: 0.5);
//     overflow-x: hidden;
//     @include flexContainer ($direction: column, $align-items: center, $align-content: flex-end, $justify-content: flex-end, $flex-wrap: null);
//     @include position($position: fixed);
//     padding: 10px;
//     bottom: 0px;
//     z-index: 10;
//     background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
//     transition: all .5s;


//     &.hide {
//         transform: translateY(130px);
//     }

// }


// .overflow {
//     @include divDimensions (100%, auto, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
//     @include flexContainer ($direction: row, $align-items: flex-end, $align-content: null, $justify-content: null, $flex-wrap: null);
//     overflow: hidden;
// }

// .button-wrapper {
//     @include divDimensions (100%, auto, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
//     @include flexContainer ($direction: row, $align-items: flex-end, $align-content: null, $justify-content: null, $flex-wrap: null);
//     overflow: auto;
// }

// .button-background {
//     @include divDimensions (100%, 150px, $max-width: 100%, $max-height: auto, $min-width: 200px, $min-height: null);
//     @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
//     @include backgroundColor(grey, $opacity: 1);
//     @include margin($top: 0px, $right: 10px, $bottom: 0px, $left: 5px);
//     color: white;
//     @include border($border-radius: null, $border-style: solid, $border-width: 0px, $border-color: grey, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
//     cursor: pointer;
//     transition: all 300ms ease-out;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     position: relative;
//     box-sizing: border-box;
//     filter: grayscale(50%);

//     &.active {
//         @include border($border-radius: null, $border-style: solid, $border-width: 5px, $border-color: white, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
//         filter: grayscale(0%);
//     }

// }

// .overlay {
//     position: absolute;
//     /* Sit on top of the page content */
//     width: 100%;
//     /* Full width (cover the whole page) */
//     height: 100%;
//     /* Full height (cover the whole page) */
//     top: 0;
//     left: 0;
//     background-color: rgba(0, 0, 0, 0.5);
//     /* Black background with opacity */
//     z-index: 2;
//     /* Specify a stack order in case you're using a different order for other elements */
//     cursor: pointer;
//     /* Add a pointer on hover */
// }

// .arrow {
//     @include divDimensions (30px, 30px, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
//     @include margin($top: 5px, $right: 5px, $bottom: 5px, $left: 5px);
//     cursor: pointer;
//     transform: rotate(225deg);
//     transition: all .5s;

//     @include border($border-radius: null, $border-style: solid, $border-width: null, $border-color: white, $border-left-width: 3px, $border-top-width: 3px, $border-right-width: 0px, $border-bottom-width: 0px);

//     &.hide {
//         transform: rotate(45deg);
//     }

// }



// .text {
//     z-index: 20;
//     font-family: $font-family-book;
//     font-weight: 400;
//     font-style: normal;
//     text-transform: uppercase;
//     font-size: 15px;
// }


// .text_view_nome {
//     z-index: 10000;
// }


.settings-button {
    @include divDimensions (25px, 25px, $max-width: auto, $max-height: auto, $min-width: null, $min-height: null); //@include backgroundColor($primary, $opacity: 0.5);
    @include position($position: fixed, $top: 0px, $right: 0px);
    margin: 10px;
    z-index: 10;
    cursor: pointer;
    background-image: url("./assets/images/settings.svg");
}

.exit-button {
    @include divDimensions (25px, 25px, $max-width: auto, $max-height: auto, $min-width: null, $min-height: null); //@include backgroundColor($primary, $opacity: 0.5);
    @include position($position: fixed, $top: 0px, $right: 0px);
    margin: 10px;
    z-index: 10;
    cursor: pointer;
    background-image: url("./assets/images/exit.svg");
}

.pairing-wrapper {
    @include divDimensions (100%, 100%, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
    @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    margin-top: 30px;

    & p {
        color: black;

    }
}

.pairing-button {
    @include divDimensions (250px, 50px, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
    @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    @include backgroundColor(rgb(204, 204, 204), $opacity: 1);
    color: rgb(0, 0, 0);
    @include border($border-radius: null, $border-style: solid, $border-width: 3px, $border-color: rgb(141, 141, 141), $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
    cursor: pointer;
    position: relative;
    margin: 10px;
}


.hs_scene {
    width: 50px;
    height: 50px;
    background-color: red;
    //background-image: url("./assets/images/img_action.png"); 
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
    display: block;
}

.hs_slide {
    width: 50px;
    height: 50px;
    background-image: url("./assets/images/img_image.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

.hs_video {
    width: 50px;
    height: 50px;
    background-image: url("./assets/images/img_video.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

.hs_text {
    width: 50px;
    height: 50px;
    background-image: url("./assets/images/img_text.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

.hs_info {
    width: 50px;
    height: 50px;
    background-image: url("./assets/images/img_info.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

.hs_quiz {
    width: 50px;
    height: 50px;
    background-image: url("./assets/images/img_quiz.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

a-assets,
a-scene video,
a-scene img,
a-scene audio {
    display: block;
}
/*
.reportQuiz {
    padding: 50px;
    text-align: center;
    background-color: #666666;
    height: 100vh;
}

.reportQuiz>div {
    padding: 50px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 30px;
    margin: auto;
    height:100%;
    overflow-y: scroll;
}

.argsToStudy {
    padding: 13px 25px;
    background-color: #f0f0f0;
    border-radius: 15px;
    width: 50%;
    margin: 25px auto 0px;
    text-align: left;
    min-height: 100px;
}

.questionInReport {
    color: #0464b3;
    font-size: 1.2rem;
}

.answerInReport {
    color: black;
    font-size: 1.1rem;
}

.answerBall {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #999999;
    margin-right: 10px;
    display: inline-flex;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}
.btn {
    background-color: #3B7BBC !important;  
    border-radius: 25px !important;
    color: #f5f5f5 !important;
    padding-top: 10px !important;
  }
*/