@mixin font-face($font-family:null, $font-weight: null, $font-style: null, $font-size: null) {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
}


@font-face {
    font-family: "FuturaPT-Book";
    src: url("../fonts/FuturaPT-Book.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

$primaryFont: "FuturaPT-Book";
$secondaryFont: "FuturaPT-Book";
$thirdFont: "FuturaPT-Book";

$font-family-book: 'FuturaPT-Book';
$font-family-black: 'FuturaPT-Book';
